export const AGENT_LISTS = [
  'xiaopai',
  'nemo@pplabs.org',
  'p17895959524',
  'fupeilong',
  'zhangjialin',
  'mingfuyun',
  'dayu',
  'youguo',
  'liangjiu',
  'keyun',
  'yizong',
  'wanwu',
  'liqiang123',
  'sushaoye',
  'shanyanyun',
  'ailisi',
  'shenniaokeji',
  '渠道三组',
  'luxi',
  'XGcloud1',
  'XGcloud2',
  'XGcloud3',
]
